nav {
    background-color: rgb(101, 194, 245);
}

.navbar .nav-link {
    color: white;
    font-weight: bold;
}

.nav-link:focus, .nav-link:hover {
    color: white !important;
}
