.container {
    width: 100%;
    position: relative;
    max-height: 400px;
}

.image {
    width: 100%;
    height: auto;
    opacity: 0.3;
    max-height: 400px;
    object-fit: cover;
}

.text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
}
