.container {
    padding: 20px;
    font-size: 12px;
    color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 0;
}
