.container {
    color: gray;
}

.container h3 {
    margin: 3rem;
}

.container p {
    margin: 1rem 3rem;
}
