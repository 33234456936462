.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 50px;
}

.intro {
    flex: 1;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.screenshot {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screenshot img {
    max-width: 100%;
    max-height: 400px;
    height: auto;
    object-fit: contain;
}
