.primary-button {
    background-color: rgb(101, 194, 245);
    border: none;
    color: white;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.primary-button:hover {
    color: white;
    background-color: rgb(81, 174, 225);
}

@media screen and (max-width: 768px) {
    .primary-button {
        padding: 6px 12px;
        font-size: 12px;
        border-radius: 15px;
    }
}
